import { mapMutations } from 'vuex'
import { getDictFLJ } from '~/utils/butterUtils'

export default {
  data () {
    return {
      isToolTipOpen: false
    }
  },
  computed: {
    requiredClass: function () {
      return {
        'hide': Boolean(this.fieldValue || (this.v && this.v.$error)),
        'is-required': this.v && this.v.required !== undefined,
        'is-optional': this.v && this.v.required === undefined
      }
    },
    autoComplete () {
      switch (this.fieldName) {
        case 'firstName':
          return 'given-name'
        case 'middleName':
          return 'additional-name'
        case 'lastName':
          return 'family-name'
        case 'suffix':
          return 'salutation-suffix'
        default:
          break
      }
    },
    fieldValue: {
      get () {
        return this.getCurrent ? this.getCurrent[this.fieldName] : ''
      },
      /* 
        2023-02-22 John Yee
        discussion with Heidi Burch about test issue 819
        suppress second email address if it is a duplicate of the first one
      */
      set (rawval) {
         /** 
          * Leading and trailing spaces in email fields throw validation error.
          * It doesn't hurt to trim all fields though.
          */
        const val = rawval ? rawval.trim() : rawval
        this.fieldName==='altEmail' ?
           this.update({ [this.fieldName]: (val!==this.getCurrent['email']) ? val : '' })
         : this.update({ [this.fieldName]: val || null })

        if (this.fieldName==='altEmail' && val===this.getCurrent['email']) {
          this.$buefy.dialog.alert({
            title:  this.dict.N22,
            message:  this.dict.N23,
            confirmText: this.dict.N00,
            type: 'is-warning',
            hasIcon: true,
          })
        }

        /**
         * 2023-06-28 John Yee
         * email address for DA join or existing member
         * put in variable joinDa because that is the legacy behavior
         * 
         * this.daJoinStatus is a workaround related to joinDa
         * see components/FormEmailInput.vue and pages/request/_stage/index.vue
         * this variable contains true, false, or 'already a member'
         * 
         * if the voter clears any previous characters from the email input box,
         * then this.daJoinStatus restores the initial value of joinDa
         * 
         * I think we should re-work all the code related to joinDa starting later this year .
         */
        if (this.fieldName==='DaEmail') {
          this.getCurrent.joinDa = val ? val : this.daJoinStatus
        }
      }
    },
    fieldType () {
      return this.v && this.v.$error ? 'is-danger' : ''
    },
    fieldMessages () {
      return this.v && this.v.$error ?
        Object.entries(this.v).filter(([key, value]) => key.charAt(0) !== '$' && value === false)
        .map(x => this.getDictFLJ(`request.${this.fieldName}.messages.${x[0]}`, this.dict)) : ''
    },
  },
  methods: {
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The 'getDictFLJ(dictItem, dict)' in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    toggleIsToolTipOpen () {
      this.isToolTipOpen = !this.isToolTipOpen
    },
    ...mapMutations('requests', ['update'])
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { updateFvapLeosWithButter } from '~/utils/butterUtils'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'
import axios from 'axios'

export default {
  name: 'Jurisdiction',
  mixins: [fieldLabelAndTooltip],
  props: [
    'value',
    'state',
    'butterLeos',
    'type',
    'label',
    'message',
    'validations',
    'placeholder',
    'fieldName',
    'instructions',
    'toolTipTitle',
    'toolTipContent',
    'dict'
  ],
  async mounted () {
    this.loading = true

    if (this.state) {
      let fvapLeos = (await axios.get(`/leos/${this.state}-leos.json`)).data
      let ul = updateFvapLeosWithButter(fvapLeos, this.butterLeos)
      this.leos = ul.filter(leo => leo.fpcaOffice).sort((a,b)=>{ return (a.n<b.n)?-1:1 })
    } else {
      this.leos = []
    }

    if (this.leos.length === 1) {
      this.isSingleLeoState = true
      this.updateLeo(this.leos[0])
    } else {
      this.isSingleLeoState = false
    }

    this.chosenLeo = this.leo.i
    this.loading = false
  },
  data () {
    return {
      leos: [],
      chosenLeo: '-1',
      isSingleLeoState: false,
      typedJurisdiction: '',
      isOpen: false,
      loading: true,
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    votAdr () {
      return this.getCurrent.votAdr
    },
    leo () {
      return this.getCurrent.leo ? this.getCurrent.leo : {}
    },
    filteredLeosAll () {
      /**
       * 2023-01-16 Heidi Burch
       * suggested we show voters the entire list of LEO jurisdictions after the suggested list
       * keeping the filtered not-suggested list in case we want to use it sometime later
       */
      this.myLeos()
      return this.leos
    },
    filteredLeosSuggested () {
      this.myLeos()
      return this.leos.filter(x=>x.suggested)
    },
    filteredLeosNotSuggested () {
      this.myLeos()
      return this.leos.filter(x=>!x.suggested)
    },
    ...mapGetters("requests", ["getCurrent"]),
  },
  methods: {
    decodeHtmlEntity (str) {
      str = str
        .replace(/&apos;/g, "'")
        .replace(/&quot;/g, '"')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&amp;/g, '&')
      return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec)
      })
    },
    updateLeo (value) {
      let leo = {}
      if (value) {
        Object.keys(value).forEach(x => {
          if (value[x]) leo[x] = value[x]
        })
      }
      delete leo.suggested
      this.$store.commit('requests/update', { leo: leo })
    },
    selectLeo () {
      const selectedLeo = this.filteredLeosAll.filter(item => item.i==this.chosenLeo)[0]
      this.updateLeo (selectedLeo)
      this.typedJurisdiction=selectedLeo.n
      this.$emit('input', this.typedJurisdiction)
    },
    myLeos () {
      let votAdrC = this.votAdr.C
      let votAdrY = this.votAdr.Y

      this.leos.forEach(x => {
        x.suggested = false

        // remove 'village', etc. from the the city field that the voter entered
        // because the FVAP city name may not contain 'village', etc. 
        if (votAdrC) {
          let scr1 = votAdrC.toLowerCase().replace('village', '').replace('town', '').replace('township', '').replace('parish', '').replace('borough', '').replace('city', '')
          if (
              ( x.n.toLowerCase().indexOf(scr1.trim()) > -1 ||
                x.j.toLowerCase().indexOf(scr1.trim()) > -1 )
              )
          {
            x.suggested = true
          }
        }

        // remove 'county', etc. from the the county field that the voter entered
        // because the FVAP county name may not contain 'county', etc. 
        if (votAdrY) {
          let scr2 = votAdrY.toLowerCase().replace('county', '').replace('island', '')
          if (
              ( x.n.toLowerCase().indexOf(scr2.trim()) > -1 ||
                x.j.toLowerCase().indexOf(scr2.trim()) > -1 )
              )
          {
            x.suggested = true
          }
        }
      })
    },
  }
}
